<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="上传协议"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="协议主体">
          <a-select
            :loading="loadingContractingPartyOptions"
            show-search
            :filter-option="filterOption"
            v-decorator="[
              'contracting_party_id',
              { rules: [{ required: true, message: '请选择协议主体' }] },
            ]"
            placeholder="请选择协议主体"
          >
            <a-select-option
              v-for="contracting_party in contractingPartyOptions"
              :key="contracting_party.id"
              :value="contracting_party.id"
              :disabled="!contracting_party.effective"
            >
              {{ contracting_party.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="协议名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入协议名称' },
                { max: 100, message: '最多100个字符' }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="版本号">
          <a-input
            v-decorator="['version', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入版本号' },
                { max: 50, message: '最多50个字符' }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="协议" required>
          <upload-custom-file
            accept=".pdf"
            hint="支持pdf格式，100MB以内"
            :max-size="100*1024*1024"
            @change="handleUploadChange"
            v-decorator="['attachment', {
              rules: [{ validator: checkFileList }],
            }]"
          />
        </a-form-item>

        <a-form-item label="是否立即使用">
          <a-checkbox v-decorator="['is_using', { valuePropName: 'checked' }]" />
        </a-form-item>

        <a-form-item label="说明">
          <a-input
            v-decorator="['description']"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { CreateAgreements } from '@/api/agreement'
import { findContractingPartyOptions } from '@/api/contracting_party'
import UploadCustomFile from '@/components/Upload/CustomFile'

export default {
  name: 'UploadRegisteredAgreement',
  components: { UploadCustomFile },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fileList: [],
      form: this.$form.createForm(this, { name: 'registered_agreement' }),
      submitting: false,
      agreementType: 'platform_service',
      contractingPartyOptions: [],
      loadingContractingPartyOptions: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchContractingPartiesOption()
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleUploadChange(fileList) {
      this.fileList = fileList
    },

    checkFileList(rule, value, callback) {
      if (!this.fileList[0] || this.fileList[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传协议')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.fileList, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            agreement_type: this.agreementType,
            attachment: this.fileList[0].response
          }
          CreateAgreements(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },

    fetchContractingPartiesOption() {
      this.loadingContractingPartyOptions = true
      findContractingPartyOptions().then((res) => {
        if (res.code === 0) {
          this.contractingPartyOptions = res.data
        }
        this.loadingContractingPartyOptions = false
      })
    }
  }
}
</script>
