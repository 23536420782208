var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"上传协议"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"协议主体"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'contracting_party_id',
            { rules: [{ required: true, message: '请选择协议主体' }] } ]),expression:"[\n            'contracting_party_id',\n            { rules: [{ required: true, message: '请选择协议主体' }] },\n          ]"}],attrs:{"loading":_vm.loadingContractingPartyOptions,"show-search":"","filter-option":_vm.filterOption,"placeholder":"请选择协议主体"}},_vm._l((_vm.contractingPartyOptions),function(contracting_party){return _c('a-select-option',{key:contracting_party.id,attrs:{"value":contracting_party.id,"disabled":!contracting_party.effective}},[_vm._v(" "+_vm._s(contracting_party.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"协议名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入协议名称' },
              { max: 100, message: '最多100个字符' }
            ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入协议名称' },\n              { max: 100, message: '最多100个字符' }\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"版本号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['version', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入版本号' },
              { max: 50, message: '最多50个字符' }
            ]
          }]),expression:"['version', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入版本号' },\n              { max: 50, message: '最多50个字符' }\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"协议","required":""}},[_c('upload-custom-file',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachment', {
            rules: [{ validator: _vm.checkFileList }],
          }]),expression:"['attachment', {\n            rules: [{ validator: checkFileList }],\n          }]"}],attrs:{"accept":".pdf","hint":"支持pdf格式，100MB以内","max-size":100*1024*1024},on:{"change":_vm.handleUploadChange}})],1),_c('a-form-item',{attrs:{"label":"是否立即使用"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_using', { valuePropName: 'checked' }]),expression:"['is_using', { valuePropName: 'checked' }]"}]})],1),_c('a-form-item',{attrs:{"label":"说明"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description']),expression:"['description']"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }